<template>
  <v-dialog v-model="dialog" width="650" persistent fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        depressed
        :color="testRecord ? 'info' : 'primary'"
        v-bind="attrs"
        v-on="on"
        :disabled="!studentData.assisted"
      >
        <v-icon size="18">mdi-format-list-checks</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formIsValid" ref="form" @submit.prevent="saveTest">
        <v-card-title class="text-h5">
          <v-row class="align-center">
            <v-col>
              Test práctico {{ studentData.student_name }} {{ studentData.student_surname }}
            </v-col>
            <v-col cols="auto">
              <v-btn fab small depressed @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row
            v-for="(question, i) in recordData.questions"
            :key="question.id"
            class="align-baseline"
            :class="{ 'grey lighten-3': i % 2 === 0 }"
          >
            <v-col
              cols="12"
              sm="8"
              class="body-1"
              :class="{ 'font-weight-bold': question.important }"
            >
              {{ question.text }}
            </v-col>
            <v-col cols="4">
              <v-radio-group
                v-model="question.answer"
                row
                hide-details="auto"
                :rules="[$data.$globalRules.required]"
                class="mt-0"
              >
                <v-radio :value="true" label="Sí"></v-radio>
                <v-radio :value="false" label="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="4" class="text-center">
              <v-checkbox label="Práctica aprobada" v-model="recordData.approvedPractical" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="recordData.comments" label="Observaciones" outlined counter />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" :loading="updatingTest">Guardar test</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    studentData: { type: Object, required: true },
    testRecord: { type: Object || null, required: false },
  },
  data: () => ({
    dialog: null,
    formIsValid: null,
    updatingTest: null,
    recordData: {
      questions: [
        { id: 1, text: "Aproximación segura", answer: null },
        { id: 2, text: "Valoración consciencia", answer: null },
        { id: 3, text: "Chilla ayuda", answer: null },
        { id: 4, text: "Permeabiliza Via Aérea", answer: null },
        { id: 5, text: "Comprueba respiración", important: true, answer: null },
        { id: 6, text: "Asegura ayuda y pide DEA", answer: null },
        { id: 7, text: "Relación compressiones/ventilaciones 30:2", answer: null },
        { id: 8, text: "Calidad compresiones", important: true, answer: null },
        { id: 9, text: "Calidad ventilaciones", answer: null },
        { id: 10, text: "Colocación parches DEA", answer: null },
        { id: 11, text: "Desfibrilación segura", important: true, answer: null },
        { id: 12, text: "Compresiones inmediatas", answer: null },
      ],
      approvedPractical: null,
      comments: null,
    },
  }),
  mounted() {
    if (this.testRecord) {
      this.recordData = JSON.parse(JSON.stringify(this.testRecord));
    }
  },
  methods: {
    async saveTest() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        try {
          this.updatingTest = true;
          const payload = {
            inscription_id: this.studentData.inscription_id,
            recordData: this.recordData,
            form_id: 1,
            approvedPractical: this.recordData.approvedPractical,
          };
          await this.$store.state.currentService.postTestData(payload);
          this.$emit("getSessionData");
          this.$notifySuccess(undefined, "Test práctico guardado correctamente.");
          this.dialog = false;
        } catch (e) {
          this.$notifyError(undefined, "Error al guardar el resultado del test | " + e);
        } finally {
          this.updatingTest = false;
        }
      }
    },
  },
  watch: {
    dialog(opened) {
      if (opened) {
        this.comment = this.studentData.comment || "";
      }
    },
  },
};
</script>

<style scoped></style>
